import Link from 'next/link'

export default function InfoLink({
  onIconClick,
}: {
  onIconClick: (section: string) => void
}) {
  return (
    <ul className="w-full mob-link-2">
      <li
        onClick={() => {
          onIconClick('Store Locator')
        }}
        className="mr-3 sm:inline-block"
      >
        <Link legacyBehavior href="/stores" passHref>
          <a href="/stores" className="text-sm font-semibold">
            Store Locator
          </a>
        </Link>
      </li>
      <li
        onClick={() => {
          onIconClick('Terms And Conditions')
        }}
        className="mr-3 sm:inline-block"
      >
        <Link legacyBehavior href="/terms-and-conditions" passHref>
          <a href="/terms-and-conditions" className="text-sm font-semibold">
            Terms of Service
          </a>
        </Link>
      </li>
      <li
        className="mr-3 sm:inline-block"
        onClick={() => {
          onIconClick('Privacy Policy')
        }}
      >
        <Link legacyBehavior href="/privacy-policy" passHref>
          <a href="/privacy-policy" className="text-sm font-semibold">
            Privacy Policy
          </a>
        </Link>
      </li>
      <li
        className="mr-3 sm:inline-block"
        onClick={() => {
          onIconClick('Blog')
        }}
      >
        <Link legacyBehavior href="/blog" passHref>
          <a className="text-sm font-semibold">Blog</a>
        </Link>
      </li>
      <li
        className="mr-3 sm:inline-block"
        onClick={() => {
          onIconClick('About Us')
        }}
      >
        <Link legacyBehavior href="/about-us" passHref>
          <a className="text-sm font-semibold">About Us</a>
        </Link>
      </li>
      <li
        className="mr-3 sm:inline-block"
        onClick={() => {
          onIconClick('FAQ')
        }}
      >
        <Link legacyBehavior href="/faq" passHref>
          <a href="/faq" className="text-sm font-semibold">
            FAQs
          </a>
        </Link>
      </li>
      <li
        className="mr-3 sm:inline-block"
        onClick={() => {
          onIconClick('Collaboration')
        }}
      >
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/damenschofficial/"
          className="text-sm font-semibold"
        >
          Collaboration
        </a>
      </li>
    </ul>
  )
}
