export default function SocialLinks() {
  return (
    <ul className="flex items-center justify-center social-icon sm:justify-between">
      <li className="inline-block mr-6 sm:mr-4">
        <a
          rel="noreferrer"
          target="_blank"
          href="https://www.instagram.com/damenschofficial/"
          className="inline-block"
        >
          <i className="sprite-icon sprite-insta"></i>
        </a>
      </li>
      <li className="inline-block mr-6 sm:mr-4">
        <a
          rel="noreferrer"
          target="_blank"
          href="https://www.facebook.com/Damenschofficial/"
          className="inline-block"
        >
          <i className="sprite-icon sprite-fb"></i>
        </a>
      </li>
      <li className="inline-block mr-6 sm:mr-4">
        <a
          rel="noreferrer"
          target="_blank"
          href="https://twitter.com/damensch_in?lang=en"
          className="inline-block"
        >
          <i className="sprite-icon sprite-twitter"></i>
        </a>
      </li>
      <li className="inline-block mr-6 sm:mr-4">
        <a
          rel="noreferrer"
          target="_blank"
          href="https://www.youtube.com/channel/UCmHTYSGgtCijnH82npTrCJw"
          className="inline-block"
        >
          <i className="sprite-icon sprite-youtube"></i>
        </a>
      </li>
      <li className="inline-block last:mr-0">
        <a
          rel="noreferrer"
          target="_blank"
          href="https://www.linkedin.com/company/damensch"
          className="inline-block"
        >
          <i className="sprite-icon sprite-linkedin"></i>
        </a>
      </li>
    </ul>
  )
}
