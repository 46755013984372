import { FC } from 'react'
import { FooterLogo } from '@components/ui'
import SocialLink from '../Footer/SocialLink'
import InfoLink from '../Footer/InfoLink'
import FooterMenu from '../Footer/FooterMenu'
import FooterInfoText from '../Footer/FooterInfoText'
import Newsletter from '../Footer/Newsletter'
import { COPYRIGHT_FOOTER_INFO } from '@components/utils/textVariables'
import { getCurrentPage } from '@framework/utils/app-util'
import { IExtraProps } from '../Layout/Layout'
import { useUI } from '@components/ui/context'
import { EmptyGuid } from '@components/utils/constants'
import { GA_EVENT } from 'hooks/ga_event.modal'
import { recordGA4Event } from '@components/services/analytics/ga4'
import { useMoEngageEvent } from 'hooks/useMoengageEvent'
import { MO_ENGAGE_EVENT } from 'hooks/moengage_event.modal'

declare const window: any

interface Props {
  config: []
}

const Footer: FC<Props & IExtraProps> = ({ config, deviceInfo }) => {
  const { user } = useUI()
  const trackMoEngageEvent = useMoEngageEvent()
  const { isMobile, isIPadorTablet } = deviceInfo
  let deviceCheck = ''
  if (isMobile || isIPadorTablet) {
    deviceCheck = 'Mobile'
  } else {
    deviceCheck = 'Desktop'
  }
  let currentPage = getCurrentPage()

  function footerClick(detail: any) {
    if (currentPage) {
      recordGA4Event(window, GA_EVENT.FOOTER_QUERY_CLICK, {
        device: deviceCheck,
        page_clicked_on: currentPage,
        click_detail: detail,
        loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
      })

      trackMoEngageEvent(
        MO_ENGAGE_EVENT.FOOTER_CLICK,
        {
          Device: deviceCheck,
          Page_Clicked_On: currentPage,
          Click_Detail: detail,
        },
        window
      )
    }
  }

  return (
    <footer
      aria-labelledby="footer-heading"
      id="footer-wrapper"
      className="py-8 mt-6 bg-transparent bg-footer dark:text-black"
    >
      <div className="container px-4 mx-auto lg:px-6">
        <div className="flex flex-col w-full Footer-top sm:flex-col md:flex-col lg:flex-row">
          <div className="w-full sm:w-full md:w-full lg:w-2/4 footer-left-top">
            <div
              onClick={() => footerClick('Logo')}
              className="footer-logo relative w-full px-2 sm:px-6 after:content-[''] after:absolute after:bottom-0 after:left-14 sm:after:left-20 after:h-2 after:bg-orange-500 after:w-10/12"
            >
              <FooterLogo />
            </div>
            <FooterInfoText />
            <Newsletter />
          </div>
          <div className="w-full sm:w-full md:w-full lg:w-2/4">
            <FooterMenu config={config} deviceInfo={deviceInfo} />
          </div>
        </div>
        {/* footer top End */}

        {/* Footer bottom start */}
        {/* border-t-2 border-gray-300 mt-6 */}
        <div className="relative w-full px-2 py-4 pb-0 sm:px-6 sm:py-8 md:px-0 md:py-8 lg:px-6 lg:py-0">
          <div
            onClick={() => footerClick('Social Links')}
            className="w-full sm:absolute sm:-top-16 sm:inline-block sm:p-3 sm:left-0 social-section sm:px-6 bg-footer md:top-0 md:px-0 lg:-top-16 lg:px-6"
          >
            <SocialLink></SocialLink>
          </div>
          <div className="flex flex-row py-4">
            <div className="hidden w-3/5 sm:flex">
              <InfoLink
                onIconClick={(section: string) => footerClick(section)}
              />
            </div>
            <div className="w-full text-center sm:w-2/5 sm:text-right">
              <p onClick={() => footerClick('Copyright')} className="text-sm">
                {COPYRIGHT_FOOTER_INFO}
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
